import React from "react";
import ReactExport from "react-export-excel";
import {Button} from "antd";
import {formatDateToReadable} from "../ui-util/general-variable";
import {useSelector} from "react-redux";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportAnalyticPageComponent = (props) => {
    const {dataList} = props
    const {startDate, endDate} = useSelector(state => state.analyticsReducer)

    return (
        <ExcelFile element={<Button disabled={dataList.length < 1}>Export</Button>} filename={"Analytics Page"}>
            <ExcelSheet data={dataList} name={formatDateToReadable(startDate) === formatDateToReadable(endDate) ? `${formatDateToReadable(startDate)}` : `${formatDateToReadable(startDate)} - ${formatDateToReadable(endDate)}`}>
                <ExcelColumn label="Nama Page" value="page"/>
                <ExcelColumn label="Count" value="count"/>
            </ExcelSheet>
        </ExcelFile>
    )
}

export default ExportAnalyticPageComponent
