import React from "react";
import {Switch} from "react-router-dom";
import Route from './Route';
import LandingPage from "../pages/landing";
import UserSettingPage from "../pages/user-setting";
import LinkSetting from "../pages/link-setting";
import MainMenuSetting from "../pages/main-menu-setting";
import MenuSetting from "../pages/menu-setting";
import RoleSetting from "../pages/role-setting";
import CreateRolePage from "../pages/create-role";
import PowerBiPage from "../pages/power-bi";
import AnalyticPage from "../pages/analytic-page";
import AnalyticUserPage from "../pages/analytic-user";
import MaintenancePage from "../pages/maintenance";
import BlastEmail from "../pages/blast-email";
import BlastEmailPage from "../pages/blast-email";

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={LandingPage}/>

            <Route path="/menu/power-bi/:id" component={PowerBiPage} isPrivate/>
            <Route path="/sub-menu/power-bi/:id" component={PowerBiPage} isPrivate/>
            <Route path="/admin-setting" component={UserSettingPage} isPrivate/>
            <Route path="/sub-sub-menu-setting" component={LinkSetting} isPrivate/>
            <Route path="/main-menu-setting" component={MainMenuSetting} isPrivate/>
            <Route path="/sub-menu-setting" component={MenuSetting} isPrivate/>
            <Route path="/role-setting" component={RoleSetting} isPrivate/>
            <Route path="/create-role" component={CreateRolePage} isPrivate/>
            <Route path="/analytic-page" component={AnalyticPage} isPrivate/>
            <Route path="/analytic-user" component={AnalyticUserPage} isPrivate/>
            <Route path="/update-role/:id" component={CreateRolePage} isPrivate/>
            <Route path="/blast-email" component={BlastEmailPage} isPrivate/>
        </Switch>
    )
}
