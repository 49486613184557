import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
const token = localStorage.getItem('TOKEN_AUTH');

const createMenuApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/menu/create`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const getMenuListApi = () => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v1/menu/list`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const updateMenuApi = (id, requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/menu/update/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params: {
                    id
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const deleteMenuApi = (id) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v1/menu/delete/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const getMenuListByMainMenuApi = (mainMenuId) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v1/menu/list/${mainMenuId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const getMenuDetailBySlugApi = (slug) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v1/menu/detail/${slug}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

export {
    createMenuApi,
    getMenuListApi,
    updateMenuApi,
    deleteMenuApi,
    getMenuListByMainMenuApi,
    getMenuDetailBySlugApi
}
