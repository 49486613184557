import React from "react";
import {Button} from "antd";
import ReactExport from "react-export-excel";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../ui-util/general-variable";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportAnalyticUserComponent = (props) => {
    const {dataList} = props
    const {startDate, endDate} = useSelector(state => state.analyticsReducer)

    return (
        <ExcelFile element={<Button disabled={dataList.length < 1}>Export</Button>} filename={"Analytics User"}>
            <ExcelSheet data={dataList}
                        name={formatDateToReadable(startDate) === formatDateToReadable(endDate) ? `${formatDateToReadable(startDate)}` : `${formatDateToReadable(startDate)} - ${formatDateToReadable(endDate)}`}
            >
                <ExcelColumn label="Nama" value="name"/>
                <ExcelColumn label="Email" value="email"/>
                <ExcelColumn label="Total Page View" value="totalPage"/>
                <ExcelColumn label="Page" value="page"/>
                <ExcelColumn label="Tanggal Akses" value="accessTime"/>
            </ExcelSheet>
        </ExcelFile>
    )
}

export default ExportAnalyticUserComponent
